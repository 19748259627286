import React, { useEffect, useState } from "react";
import "./payslip.css";
import axios from "axios";
import Loading from "../Loading";
import LeaveTable from "../hr/LeaveTable";

const Attendence = (props) => {
  const url = process.env.REACT_APP_API_URL;
  const empId = localStorage.getItem("_id")
  const token = localStorage.getItem("token");
  const [clockInsData, setclockInsData] = useState({});
  const [regularHeight, setRegularHeight] = useState(0);
  const [lateHeight, setLateHeight] = useState(0);
  const [earlyHeight, setEarlyHeight] = useState(0);
  const [tableData, setTableData] = useState([]);

  function calculateOverallBehavior(regularCount, lateCount, earlyCount) {
    const totalCount = regularCount + lateCount + earlyCount;

    if (totalCount === 0) {
      return "No data"; // Handle case with no logins
    }

    const regularAvg = (regularCount / totalCount) * 100;
    const lateAvg = (lateCount / totalCount) * 100;
    const earlyAvg = (earlyCount / totalCount) * 100;

    // Determine the dominant behavior based on the highest percentage
    if (regularAvg >= lateAvg && regularAvg >= earlyAvg) {
      return "Regular";
    } else if (lateAvg >= regularAvg && lateAvg >= earlyAvg) {
      return "Late";
    } else {
      return "Early";
    }
  }

  function calculateWorkAvailablity(empWorkHour, companyWorkHour) {
    if (empWorkHour !== 0) {
      return ((empWorkHour / companyWorkHour) * 100).toFixed(2)
    } else {
      return 0;
    }
  }

  useEffect(async () => {
    const dashboard = await axios.get(`${url}/api/clock-ins/${empId}`, {
      headers: {
        authorization: token || ""
      }
    })
    setclockInsData(dashboard.data);    
    setTableData(dashboard.data.clockIns);
    const { totalEarlyLogins, totalLateLogins, totalRegularLogins } = dashboard.data;
    const totalLogins = totalEarlyLogins + totalLateLogins + totalRegularLogins

    // Calculate height percentages
    if (totalLogins > 0) {
      setRegularHeight((totalRegularLogins / totalLogins) * 100);
      setLateHeight((totalLateLogins / totalLogins) * 100);
      setEarlyHeight((totalEarlyLogins / totalLogins) * 100);
    }
  }, [empId])

  return (
    <div>
      <div className="leaveDateParent">
        <div className="payslipTitle">
          Attendance
        </div>
        <div>
          <input
            type="date"
            className="dateInput"
          />
        </div>
      </div>

      {/* <div className="container"> */}
      {clockInsData && tableData.length > 0 ?
        <>
          <div className="row w-100 mx-auto">
            <div className="chartParent">
              <div className="col-lg-3 regular" style={{ height: `${regularHeight}%` }}>
                <div className="text-center">
                  <div className="payslipTitle py-1" style={{ color: "#146ADC" }}>{clockInsData.totalRegularLogins} Days</div>
                  <div className="leaveDays text-center" style={{ color: "#146ADC" }}>Regular</div>
                </div>
              </div>
              <div className="col-lg-3 early" style={{ height: `${earlyHeight}%` }}>
                <div className="text-center">
                  <div className="payslipTitle py-1" style={{ color: "#146ADC" }}>{clockInsData.totalEarlyLogins} Days</div>
                  <div className="leaveDays text-center" style={{ color: "#146ADC" }}>Early</div>
                </div>
              </div>
              <div className="col-lg-3 late" style={{ height: `${lateHeight}%` }}>
                <div className="text-center">
                  <div className="payslipTitle py-1" style={{ color: "#146ADC" }}>{clockInsData.totalLateLogins} Days</div>
                  <div className="leaveDays text-center" style={{ color: "#146ADC" }}>Late</div>
                </div>
              </div>
              <div className="col-lg-3 leave" style={{ height: '10%' }}>
                <div className="text-center">
                  <div className="payslipTitle py-1" style={{ color: "#146ADC" }}>{clockInsData.totalLeaveDays} Days</div>
                  <div className="leaveDays text-center" style={{ color: "#146ADC" }}>Leave</div>
                </div>
              </div>
            </div>
          </div>

          <div className="leaveBoard">
            <div className="leaveData">
              <div className="d-flex flex-column">
                <div className="leaveDays">
                  {clockInsData.companyTotalWorkingHour}:00:00
                </div>
                <div className="leaveDaysDesc">
                  Total schedule hour
                </div>
              </div>
            </div>
            <div className="leaveData">
              <div className="d-flex flex-column">
                <div className="leaveDays">
                  00:00:00
                </div>
                <div className="leaveDaysDesc">
                  Leave hour
                </div>
              </div>
            </div>
            <div className="leaveData">
              <div className="d-flex flex-column">
                <div className="leaveDays" style={{ color: "#146ADC" }}>
                  %{calculateWorkAvailablity(Number(clockInsData.totalEmpWorkingHours), clockInsData.companyTotalWorkingHour)}
                </div>
                <div className="leaveDaysDesc" >
                  Total work availability
                </div>
              </div>
            </div>
            <div className="leaveData">
              <div className="d-flex flex-column">
                <div className="leaveDays text-success">
                  {clockInsData.totalEmpWorkingHours}:00:00
                </div>
                <div className="leaveDaysDesc">
                  Total active hour
                </div>
              </div>
            </div>
            <div className="leaveData">
              <div className="d-flex flex-column">
                <div className="leaveDays text-danger">
                  {(clockInsData.companyTotalWorkingHour - Number(clockInsData.totalEmpWorkingHours)).toFixed(0)} hour
                </div>
                <div className="leaveDaysDesc">
                  Balance
                </div>
              </div>
            </div>
            <div style={{ width: "30%", margin: "10px" }} >
              <div className="d-flex flex-column">
                <div className="leaveDays">
                  {calculateOverallBehavior(clockInsData.totalRegularLogins, clockInsData.totalLateLogins, clockInsData.totalEarlyLogins)}
                </div>
                <div className="leaveDaysDesc">
                  Average Behaviour
                </div>
              </div>
            </div>
          </div>

          <LeaveTable data={tableData} />
        </>
        : <Loading />}

    </div>
  )
};

export default Attendence;
