import React, { useEffect, useState, useRef } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
// import axios from 'axios';
import { addDataAPI, getDataAPI, updateDataAPI, removeClockinsData } from "./ClockInsApi";
import { toast } from 'react-toastify';
import './style.css';
import CustomDropdown from './CustomDropdown';

const ClockIns = ({ updateClockins }) => {
    const clockinsId = localStorage.getItem('clockinsId');
    const [timeOption, setTimeOption] = useState(localStorage.getItem("timeOption") || "login");
    const currentDate = new Date();
    const currentHours = currentDate.getHours().toString().padStart(2, '0');
    const currentMinutes = currentDate.getMinutes().toString().padStart(2, '0');
    const currentTime = `${currentHours}:${currentMinutes}`;
    const startAndEndTime = {
        startingTime: '00:00',
        endingTime: '00:00',
        takenTime: 0,
        timeHolder: 0,
    };
    const [workTimeTracker, setWorkTimeTracker] = useState({
        date: currentDate.toISOString().split("T")[0],
        login: {
            startingTime: '00:00',
            endingTime: '00:00',
            takenTime: 0,
            timeHolder: 0,
            // givenTime: 0
        },
        meeting: {
            startingTime: '00:00',
            endingTime: '00:00',
            takenTime: 0,
            timeHolder: 0,
            // givenTime: 0
        },
        morningBreak: {
            startingTime: '00:00',
            endingTime: '00:00',
            takenTime: 0,
            timeHolder: 0,
            // givenTime: 0
        },
        lunch: { ...startAndEndTime },
        eveningBreak: { ...startAndEndTime },
        event: { ...startAndEndTime }
    });

    const [endTime, setEndTime] = useState(localStorage.getItem('countdownEndTime') && localStorage.getItem("timeOption") === timeOption ? parseInt(localStorage.getItem('countdownEndTime')) : null);
    const [isPaused, setIsPaused] = useState(localStorage.getItem("isPaused") === "false" ? false : true); // return from localStorage is string, change to boolean. 
    const [ranTime, setRanTime] = useState(0);
    const countdownApi = useRef(null);

    // useEffect(() => {
    //     if (endTime && localStorage.getItem("isPaused") === "false") {
    //         countdownApi.current.start();
    //     }
    // }, [endTime]);

    useEffect(() => {
        if (!isPaused && endTime) {
            const interval = setInterval(() => {
                setRanTime((prevTime) => prevTime + 1000);
            }, 1000);

            return () => {
                clearInterval(interval);
                setRanTime(0);
            };
        }
    }, [isPaused]);

    const startCountdown = async () => {
        countdownApi.current.start();
        localStorage.setItem('countdownEndTime', endTime);
        localStorage.setItem('isPaused', false);
        localStorage.setItem("timeOption", timeOption);
        setIsPaused(false);
    };

    const stopCountdown = async () => {
        await countdownApi.current.pause();
        // const timeHolderValue = Date.now() > endTime ? Date.now() - endTime : endTime - Date.now();
        if (Date.now() > endTime) {
            localStorage.setItem("countdownEndTime", endTime)
        }
        localStorage.setItem("countdownEndTime", endTime)
        localStorage.setItem('isPaused', true);
        setIsPaused(true);
    };

    const startTimer = async () => {
        toast.success(`${timeOption} timer has been started!`);
        if (isPaused) {
            let updateState;
            setWorkTimeTracker((prevState) => (
                updateState = {
                    ...prevState,
                    [timeOption]: {
                        ...prevState[timeOption],
                        startingTime: currentTime
                    }
                }));
            console.log(workTimeTracker);
            await startCountdown();

            // Call addDataAPI() if clockinsId is not present
            if (!clockinsId) {
                await addDataAPI(updateState);
            }
            else {
                const updatedState = (prev) => ({
                    ...prev,
                    [timeOption]: {
                        ...prev[timeOption],
                        startingTime: currentTime
                    },
                });

                // Call the API with the updated state
                updateDataAPI(updatedState(workTimeTracker));
                await updateDataAPI(updateState)
            }
        }
    };

    const stopTimer = async () => {
        toast.error("Timer has been stopped!");
        await stopCountdown();

        const timeHolderValue = Date.now() > endTime ? Date.now() - endTime : endTime - Date.now();

        const updatedState = (prev) => ({
            ...prev,
            [timeOption]: {
                ...prev[timeOption],
                endingTime: currentTime,
                timeHolder: Math.abs(timeHolderValue),
                takenTime: ranTime,
            },
        });

        // console.log(updatedState);
        // Call the API with the updated state
        await updateDataAPI(updatedState(workTimeTracker));
        updateClockins()
    };


    const updateWorkTracker = async (value) => {
        setTimeOption(value);
        if (clockinsId) {
            const { timeData } = await getDataAPI(clockinsId);
            setWorkTimeTracker(timeData);
        }
        // resetTimer()
    };

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span>Time's up!</span>;
        } else {
            return (
                <span>
                    {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
                </span>
            );
        }
    };

    //get state data from DB in Initially
    useEffect(async () => {
        if (clockinsId) {
            const { timeData } = await getDataAPI(clockinsId);
            if (currentDate.toISOString().split("T")[0] === timeData.date) {
                setWorkTimeTracker(timeData);
            } else {
                if(currentDate.toISOString().split("T")[0] !== timeData.date){
                    removeClockinsData();
                }
            }
        }
    }, []);


    // when state or timeOption is change time will update.
    useEffect(() => {
        if (isPaused) {
            if (timeOption === "login") {
                if (workTimeTracker[timeOption] && workTimeTracker[timeOption].timeHolder !== 0) {
                    setEndTime(Date.now() + workTimeTracker[timeOption].timeHolder)
                } else {
                    if (!endTime || localStorage.getItem("timeOption") !== timeOption) {
                        setEndTime(Date.now() + 1000 * 60 * 60 * 8)
                    }
                }

            } else if (timeOption === "meeting") {
                if (workTimeTracker[timeOption] && workTimeTracker[timeOption] && workTimeTracker[timeOption].timeHolder !== 0) {
                    setEndTime(Date.now() + workTimeTracker[timeOption].timeHolder)
                } else {
                    if (!endTime || localStorage.getItem("timeOption") !== timeOption) {
                        setEndTime(Date.now() + 1000 * 60 * 60)
                    }
                }
            } else if (timeOption === "morningBreak") {
                if (workTimeTracker[timeOption] && workTimeTracker[timeOption] && workTimeTracker[timeOption].timeHolder !== 0) {
                    setEndTime(Date.now() + workTimeTracker[timeOption].timeHolder)
                } else {
                    if (!endTime || localStorage.getItem("timeOption") !== timeOption) {
                        setEndTime(Date.now() + 1000 * 60 * 10)
                    }
                }
            } else if (timeOption === "lunch") {
                if (workTimeTracker[timeOption] && workTimeTracker[timeOption].timeHolder !== 0) {
                    setEndTime(Date.now() + workTimeTracker[timeOption].timeHolder)
                } else {
                    if (!endTime || localStorage.getItem("timeOption") !== timeOption) {
                        setEndTime(Date.now() + 1000 * 60 * 40)
                    }
                }
            } else if (timeOption === "eveningBreak") {
                if (workTimeTracker[timeOption] && workTimeTracker[timeOption].timeHolder !== 0) {
                    setEndTime(Date.now() + workTimeTracker[timeOption].timeHolder)
                } else {
                    if (!endTime || localStorage.getItem("timeOption") !== timeOption) {
                        setEndTime(Date.now() + 1000 * 60 * 10)
                    }
                }
            } else if (timeOption === "event") {
                if (workTimeTracker[timeOption] && workTimeTracker[timeOption].timeHolder !== 0) {
                    setEndTime(Date.now() + workTimeTracker[timeOption].timeHolder)
                } else {
                    if (!endTime || localStorage.getItem("timeOption") !== timeOption) {
                        setEndTime(Date.now() + 1000 * 60 * 60 * 4)
                    }
                }
            }
        }
    }, [timeOption, workTimeTracker]);

    return (
        <div className="clockins" >
            <div className='fw-bold d-flex justify-content-center align-items-center'>
                Dashboard
            </div>

            <div className='leaveIndicator'>
                <div className="leaveCircle">
                    <CustomDropdown isPaused={isPaused} timeOption={timeOption} updateWorkTracker={updateWorkTracker} />
                </div>
                <div className="timer">
                    <Countdown
                        key={endTime}
                        date={endTime}
                        renderer={renderer}
                        autoStart={!isPaused}
                        ref={countdownApi}
                    />
                </div>
                <div className="btnParent">
                    {
                        isPaused === true ?
                            <button
                                className="btn btn-success"
                                onClick={startTimer}
                                disabled={!Boolean(isPaused)}
                                style={{ width: "83px", height: "50px" }}
                            >
                                Start
                            </button> :
                            <button
                                className="btn btn-danger"
                                onClick={stopTimer}
                                disabled={Boolean(isPaused)}
                                style={{ width: "83px", height: "50px" }}
                            >
                                Stop
                            </button>
                    }
                </div>
            </div>
        </div>
    );
};

export default ClockIns;
