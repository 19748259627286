import React, { useEffect, useState } from "react";
import "./admin/DashboardAdmin.css";
import "./ParentStyle.css";
import logo from "../img/webnexs_logo.png";
import maleAvatar from "../img/male_avatar.png";
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import { Link } from "react-router-dom";
import Actions from "./Actions";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import ScaleIcon from '@mui/icons-material/Scale';
import ImportContactsRoundedIcon from '@mui/icons-material/ImportContactsRounded';
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';

const SideBar = ({ howIs, onLogout, handleActions, isAction, className, handleSideBar }) => {
    const [linkName, setLinkName] = useState("home");
    const [accountType, setAccountType] = useState("");
    const Name = localStorage.getItem("Name");
    const Account = localStorage.getItem("Account");
    
    useEffect(() => {
        if (Account === "1") {
            setAccountType("Admin")
        } else if (Account === "2") {
            setAccountType("HR")
        } else if (Account === "3") {
            setAccountType("Emp")
        }
    }, [])
    return (
        <>
            {isAction && <Actions handleActions={handleActions} />}
            <div id="sidebar" className={className}>
                <div className="d-flex align-items-center justify-content-center">
                    <img src={logo} alt="" className="nav-logo" />
                    <span className="logoName">
                        NexHR
                    </span>
                </div>
                <div className="avatarNameParent">
                    <div className="avatar">
                        <img src={maleAvatar} alt="" />
                    </div>
                    <div className="avatarName">
                        <div>
                            {Name} {accountType}
                        </div>
                        <div>
                            {accountType}
                        </div>
                    </div>
                </div>
                {className == "active" && <CloseRoundedIcon fontSize="large" onClick={handleSideBar} />}
                <ul className="navbar-ul" style={{ padding: "0px", marginLeft: "0px" }}>
                    {/* dashboard */}
                    <li>
                        <Link to={`${howIs}`}>
                            <div className={`nav_item ${linkName === "home" && "active"}`} onClick={() => setLinkName("home")}>
                                <GridViewRoundedIcon className="sideBarIcon" fontSize="large" /> <br />
                                <div className="sideBarTxt">
                                    Home
                                </div>
                            </div>
                        </Link>
                    </li>
                    {/* message */}
                    <li>
                        <Link to={`${howIs}`}>
                            <div className={`nav_item ${linkName === "message" && "active"}`} onClick={() => setLinkName("message")}>
                                <EmailRoundedIcon fontSize="large" className="sideBarIcon" />
                                <br />
                                <div className="sideBarTxt">
                                    Messages
                                </div>
                            </div>
                        </Link>
                    </li>
                    {/* Employee */}
                    <li>
                        <Link to={`${howIs}/employee`}>
                            <div className={`nav_item ${linkName === "employee" && "active"}`} onClick={() => setLinkName("employee")}>
                                <PeopleAltRoundedIcon fontSize="large" className="sideBarIcon" /><br />
                                <div className="sideBarTxt">Employee Management</div>
                            </div>
                        </Link>
                    </li>
                    {/* Calendar */}
                    <li>
                        <Link to={`${howIs}/salary`}>
                            <div className={`nav_item ${linkName === "calendar" && "active"}`} onClick={() => setLinkName("calendar")}>
                                <CalendarMonthRoundedIcon fontSize="large" className="sideBarIcon" /> <br />
                                <div className="sideBarTxt">
                                    Calendar
                                </div>
                            </div>
                        </Link>
                    </li>
                    {/* ClockIns */}
                    <li>
                        <Link to={`${howIs}/company`}>
                            <div className={`nav_item ${linkName === "clock" && "active"}`} onClick={() => setLinkName("clock")}>
                                <DateRangeRoundedIcon fontSize="large" className="sideBarIcon" /> <br />
                                <div className="sideBarTxt">
                                    Clock-ins
                                </div>
                            </div>
                        </Link>
                    </li>
                    {/* payroll */}
                    <li>
                        <Link to={`${howIs}/country`}>
                            <div className={`nav_item ${linkName === "payroll" && "active"}`} onClick={() => setLinkName("payroll")}>
                                <PaymentsRoundedIcon fontSize="large" className="sideBarIcon" /> <br />
                                <div className="sideBarTxt">
                                    Payroll
                                </div>
                            </div>
                        </Link>
                    </li>
                    {/* Recruitment */}
                    <li>
                        <Link to={`${howIs}/department`}>
                            <div className={`nav_item ${linkName === "hire" && "active"}`} onClick={() => setLinkName("hire")}>
                                <BusinessCenterRoundedIcon fontSize="large" /> <br />
                                <div className="sideBarTxt">
                                    Recruitment
                                </div>
                            </div>
                        </Link>
                    </li>


                    <li>
                        <Link to={`${howIs}/leave-management`}>
                            <div className={`nav_item ${linkName === "leave" && "active"}`} onClick={() => setLinkName("leave")}>
                                <ImportContactsRoundedIcon fontSize="large" />
                                <div className="sideBarTxt">
                                    Leave Management
                                </div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={`${howIs}/position`}>
                            <div className={`nav_item ${linkName === "performance" && "active"}`} onClick={() => setLinkName("performance")}>
                                <ScaleIcon fontSize="large" />
                                <div className="sideBarTxt">
                                    Performance Management
                                </div>
                            </div>
                        </Link>
                    </li>

                    <div className=" w-70 my-2">
                        <button className="btn btn-dark text-light w-100" onClick={onLogout}><PowerSettingsNewRoundedIcon />Log out</button>
                    </div>
                </ul>
            </div>
        </>
    )
};

export default SideBar;
