import React, { useEffect, useState } from "react";
import Phone from "../../assets/phone.svg";
import LeaveManagement from "../hr/LeaveManagement";
import LeaveTable from "../hr/LeaveTable";
import axios from "axios";

const Payslip = (props) => {
    const url = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("token");
    const [fieldType, setFieldType] = useState("text"); // to show date icon in dateInput
    const [payslips, setPayslips] = useState([]);

    useEffect(() => {
        async function fetchPayslips() {
            const slips = await axios.get(`${url}/api/payslip`, {
                headers: {
                    authorization: token || ""
                }
            });
            console.log(slips.data);
            setPayslips(slips.data);
        }

        fetchPayslips();
    }, [])
    return (
        <div>

            <div className="leaveDateParent">
                <div className="payslipTitle">
                    Payslip
                </div>
                <div>
                    <input
                        type="date"
                        className="dateInput"
                    />
                </div>
            </div>

            <div className="w-100 d-flex justify-content-center">
                <div className="leaveBoard">
                    <div className="leaveData">
                        <div className="d-flex flex-column">
                            <div className="leaveDays">
                                2 
                            </div>
                            <div className="leaveDaysDesc">
                                Total Payslip
                            </div>
                        </div>
                    </div>
                    <div className="leaveData">
                        <div className="d-flex flex-column">
                            <div className="leaveDays">
                                02
                            </div>
                            <div className="leaveDaysDesc">
                               Send Payslip
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "30%", margin: "10px" }} >
                        <div className="d-flex flex-column">
                            <div className="leaveDays">
                                0 
                            </div>
                            <div className="leaveDaysDesc">
                                Conflicted Payslip
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* <div className="container-fluid my-3"> */}
                <LeaveTable data={payslips} />
            {/* </div> */}
        </div>
    )
};

export default Payslip;
