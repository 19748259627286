import React, { useEffect, useState } from "react"
import LeaveManagement from "../hr/LeaveManagement";
import LeaveTable from "../hr/LeaveTable";
import { fetchLeaveRequests } from "../EmployeeHub/ClockInsApi";
import Loading from "../Loading";

const Leave = (props) => {
    const url = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("token");
    const Account = localStorage.getItem("Account");
    const empId = localStorage.getItem("_id");
    const [leaveRequests, setLeaveRequests] = useState([]);

    useEffect(async () => {
        if (empId) {
            const leaveData = await fetchLeaveRequests(empId);
            if (leaveData) {
                console.log(leaveData.requests.leaveApplication);
                setLeaveRequests(leaveData.requests.leaveApplication);
            }
        }
        return () => {
            setLeaveRequests([])
        }
    }, [])

    return (
        <div >
            {/* top date input and leave label */}
            <div className="leaveDateParent">
                <div className="payslipTitle">
                    Leave
                </div>
                <div>
                    <input
                        type="date"
                        className="dateInput"
                    />
                </div>
            </div>

            <div className="w-100 d-flex justify-content-center">
                <div className="leaveBoard">
                    <div className="leaveData">
                        <div className="d-flex flex-column">
                            <div className="leaveDays">
                                16 Days
                            </div>
                            <div className="leaveDaysDesc">
                                Leave taken
                            </div>
                        </div>
                    </div>
                    <div className="leaveData">
                        <div className="d-flex flex-column">
                            <div className="leaveDays">
                                12 Days
                            </div>
                            <div className="leaveDaysDesc">
                                Upcoming leave
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "30%", margin: "10px" }} >
                        <div className="d-flex flex-column">
                            <div className="leaveDays">
                                2 Days
                            </div>
                            <div className="leaveDaysDesc">
                                Pending request
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                leaveRequests.length > 0 ?
                    <LeaveTable data={leaveRequests} />
                    : <Loading />
            }
        </div>
    )
};

export default Leave;
