import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

export default function LeaveTable({ data }) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = React.useState([]);
    const [columns, setColumns] = React.useState([]);


    const column1 = [
        { id: 'FirstName', label: 'Name', minWidth: 170, getter: (row) => row.employee.FirstName + row.employee.LastName || 'Unknown' },
        { id: 'periodOfLeave', label: 'Period Of Leave', minWidth: 100, getter: (row) => row.periodOfLeave },
        { id: 'fromDate', label: 'Start Date', minWidth: 170, align: 'center', getter: (row) => row.fromDate ? row.fromDate.split("T")[0] : 'N/A' },
        { id: 'toDate', label: 'End Date', minWidth: 170, align: 'center', getter: (row) => row.toDate ? row.toDate.split("T")[0] : 'N/A' },
        { id: 'leaveType', label: 'Type', minWidth: 170, align: 'center', getter: (row) => row.leaveType },
        { id: 'reasonForLeave', label: 'Reason', minWidth: 170, align: 'center', getter: (row) => row.reasonForLeave },
        { id: 'status', label: 'Status', minWidth: 170, align: 'center', getter: (row) => row.status },
    ];

    const column2 = [
        { id: 'payrun', label: 'Payrun', minWidth: 170, getter: (row) => row.payrun || 'Unknown' },
        { id: 'payrunType', label: 'Payrun Type', minWidth: 150, getter: (row) => row.payrunType || 'N/A' },
        { id: 'status', label: 'Status', minWidth: 100, align: 'center', getter: (row) => row.status || 'N/A' },
        { id: 'period', label: 'Period', minWidth: 170, align: 'center', getter: (row) => row.period || 'N/A' },
        { id: 'salary', label: 'Salary', minWidth: 170, align: 'right', getter: (row) => row.salary ? `₹${row.salary}` : 'N/A' },
    ];

    const column3 = [
        { id: 'Name', label: 'First Name', minWidth: 170, getter: (row) => row.FirstName + row.LastName || 'Unknown' },
        { id: 'code', label: 'Code', minWidth: 150, getter: (row) => row.code || 'N/A' },
        { id: 'serialNo', label: 'Serial No', minWidth: 120, getter: (row) => row.serialNo || 'N/A' },
        { id: 'working', label: 'Working', minWidth: 100, align: 'center', getter: (row) => row.working ? 'Yes' : 'No' },
        { id: 'docType', label: 'Document Type', minWidth: 170, align: 'center', getter: (row) => row.docType || 'N/A' },
        { id: 'dateOfJoining', label: 'Date of Joining', minWidth: 170, align: 'center', getter: (row) => row.dateOfJoining ? row.dateOfJoining.split("T")[0] : 'N/A' },
    ];

    const column4 = [
        { id: 'date', label: 'Date', minWidth: 170, align: 'center', getter: (row) => row.date ? row.date.split("T")[0] : 'N/A' },
        { id: 'startingTime', label: 'Punch In', minWidth: 170, align: 'center', getter: (row) => row.login ? row.login.startingTime : 'N/A' }, // Extracting time in HH:MM format
        { id: 'endingTime', label: 'Punch Out', minWidth: 170, align: 'center', getter: (row) => row.login ? row.login.endingTime : 'N/A' }, // Extracting time in HH:MM format
        { id: 'behaviour', label: 'Behaviour', minWidth: 120, align: 'center', getter: (row) => row.behaviour || 'N/A' }
    ];

    // Update rows when data changes
    React.useEffect(() => {
        setRows(data || []);
        data.map((item) => {
            if (item.fromDate) {
                return setColumns(column1);
            } else if (item.code) {
                return setColumns(column3);
            } else if (item.date) {
                return setColumns(column4);
            }
            else {
                return setColumns(column2)
            }
        })
    }, [data]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div className="container-fluid my-3">
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead className='changeColor'>
                            <TableRow sx={{ backgroundColor: "gray" }}>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns.map((column) => {
                                            const value = column.getter ? column.getter(row) : row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {value || value.FirstName + value.LastName}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>

    );
}
