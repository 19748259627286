import React, { useState } from 'react';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import FreeBreakfastRoundedIcon from '@mui/icons-material/FreeBreakfastRounded';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import FestivalRoundedIcon from '@mui/icons-material/FestivalRounded';

const CustomDropdown = ({ isPaused, timeOption, updateWorkTracker }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(timeOption);

  const options = [
    { value: 'login', label: 'Login', icon: <LoginRoundedIcon fontSize='large' /> },
    { value: 'meeting', label: 'Meeting', icon: <GroupsRoundedIcon fontSize='large' /> },
    { value: 'morningBreak', label: 'Morning Break', icon: <FreeBreakfastRoundedIcon fontSize='large' /> },
    { value: 'lunch', label: 'Lunch', icon: <RestaurantIcon fontSize='large' /> },
    { value: 'eveningBreak', label: 'Evening Break', icon: <FreeBreakfastRoundedIcon fontSize='large' /> },
    { value: 'event', label: 'Event', icon: <FestivalRoundedIcon fontSize='large' /> },
  ];

  const handleOptionClick = (option) => {
    setSelectedOption(option.value);
    updateWorkTracker(option.value);
    setIsOpen(false);
  };

  return (
    <div className={`custom-dropdown ${!isPaused ? 'disabled' : ''}`}>
      <div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
        {options.find(opt => opt.value === selectedOption).icon}
        {options.find(opt => opt.value === selectedOption).label || 'Select an option'}
      </div>
      {isOpen && (
        <ul className="dropdown-list">
          {options.map(option => (
            <li key={option.value} onClick={() => handleOptionClick(option)}>
              <div className='optionList'>
                {option.icon}
                {option.label}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
