import React, { useState, useEffect } from "react";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import filterImg from "../../img/filter.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "./LeaveManagement.css";
import "../EmployeeHub/style.css";
import { deleteLeave } from "../EmployeeHub/ClockInsApi";

const LeaveHistory = ({ leaveRequests, handleUpdateLeaveRequests }) => {
    const accountNo = localStorage.getItem("Account");
    
    async function deleteLeaveReq(id){
        await deleteLeave(id);
        console.log("delete called");
        
        handleUpdateLeaveRequests();
    }
    return (
        <div className="tableParent" style={{ padding: "10px" }}>
            <div className="empform">
                <div className="d-flex justify-content-between m-1 align-items-center">
                    <div>
                        <b>Leave History</b>
                    </div>
                    <div className="filterExport">
                        <img src={filterImg} alt="" className="nav-logo" />
                        <div className="dropdown">
                            <button className="btn btn-success" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Export <ExpandCircleDownIcon />
                            </button>
                            <div className="dropdown-menu" style={{ color: "white" }} aria-labelledby="dropdownMenuButton">
                                <Link className="dropdown-item" to={`/hr/leave-request`}>Add</Link>
                                <Link className="dropdown-item" to={``}>Export</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Name(s)</th>
                            <th>Period Of Leave</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Type</th>
                            <th>Reason(s)</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            leaveRequests.map((req) => (

                                <tr key={req._id}>
                                    <td>{req.employee.FirstName}{req.employee.LastName}</td>
                                    <td>{req.periodOfLeave}</td>
                                    <td>{req.fromDate.split("T")[0]}</td>
                                    <td>{req.toDate.split("T")[0]}</td>
                                    <td>{req.leaveType}</td>
                                    <td>{req.reasonForLeave}</td>
                                    <td className={`${req.status === 'pending' ? "text-warning"
                                        : req.status === 'rejected' ? "text-danger"
                                            : req.status === 'approved' && "text-success"}`}>{req.status}</td>
                                    <td>
                                        <div className="dropdown">
                                            <button className="btn btn-secondary px-3" style={{ background: "#253D90" }} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Actions <ExpandCircleDownIcon />
                                            </button>
                                            <div className="dropdown-menu text-light" style={{ color: "white" }} aria-labelledby="dropdownMenuButton">
                                                {
                                                    req.status === "pending"
                                                    && <Link className="dropdown-item" to={`/emp/leave-request/edit/${req._id}`} >Edit</Link>
                                                }
                                                {
                                                    req.status === "pending"
                                                    && accountNo === "3" &&
                                                    <Link className="dropdown-item" onClick={()=> deleteLeaveReq(req._id)}>Delete</Link>
                                                }
                                                <Link className="dropdown-item" to={``} >View Details</Link>
                                                <Link className="dropdown-item" to={``} >Extension</Link>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default LeaveHistory;
