import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState } from 'react';
import '../SettingsStyle.css';
import axios from 'axios';
import Loading from '../../Loading';


const Permission = () => {
  const [employees, setEmployees] = useState([]);
  const names = ['Users', 'Roles', 'Direct Reports', 'Permissions'];
  const [roles, setRoles] = useState([]);
  const [empName, setEmpName] = useState("");
  const url = process.env.REACT_APP_API_URL;
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/employee", {
        headers: {
          authorization: localStorage.getItem("token") || ""
        }
      })
      .then(res => {
        setEmployees(res.data)
      })
  }, [])

  useEffect(() => {
    axios.get(url + "/api/role", {
      headers: {
        authorization: localStorage.getItem("token") || ""
      }
    })
      .then((res) => {
        setRoles(res.data)
      })
      .catch((err) => {
        console.log("roles data not found: ", err);
      })
  }, [])

  function onChangeEmp(e) {
    setEmpName(e)
    axios.get(url + `/api/employee/SearchName/${e}`, {
      headers: {
        authorization: localStorage.getItem('token') || ""
      }
    }).then((res) => {
      setEmployees(res.data)
    }).catch((err) => {
      console.log(err);
      setEmployees([])
    })
  }
  return (
    <div className="container">
      <h4>Permissions</h4>
      <p style={{ fontSize: "15px", color: "rgb(178 174 174)" }}>Assign or revoke for your employees</p>
      <div className="row">
        <div className="col-lg-4">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text" type="button" id="btnGroupAddon">
                <SearchIcon />
              </div>
            </div>
            <input type="text" className="form-control" value={empName} onChange={(e) => onChangeEmp(e.target.value)} style={{ border: "1px solid #d8d8d8", borderRight: "none", borderLeft: "none" }} placeholder="Find Name, Job" aria-label="Input group example" aria-describedby="btnGroupAddon" />
            <div className="input-group-append">
              <div className="input-group-text" type="button" id="btnGroupAddon" onClick={()=>setEmpName("")}>
                <CloseIcon />
              </div>
            </div>
          </div>
        </div>
      </div>

      {employees.length > 0 ?
        (<table className='table table-striped my-2'>
          <thead>
            <tr>
              {names.map(name => (
                <th>{name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {(employees.map((emp) => (
              <tr>
                {/* {console.log(emp.position)} */}
                <td>
                  <div className="td-parent">
                    <div className='nameHolder'>
                      {`${emp.FirstName[0]}${emp.LastName[0]}`}
                    </div>
                    {emp.FirstName} {emp.LastName} <br />
                    {/* <span className="text-secondary" style={{fontSize: "12px"}}>{emp.position.PositionName}</span> */}
                  </div>
                </td>
                <td>
                  <select name="" id="" className="form-control">
                    {
                      roles.map(role => (
                        <option value={role._id}>{role.RoleName}</option>
                      ))
                    }
                  </select>
                </td>
                <td>

                </td>

                <td className='td-parent'>
                  <input type="checkbox" className="styleRadio" name="" id="" />
                  Payroll
                </td>
              </tr>
            )))}
          </tbody>
        </table>) : (<div className='d-flex align-items-center justify-content-center'><Loading /></div>)
      }
    </div>


  )
};

export default Permission;
