
import React, { useEffect, useState } from "react";
import "./NavBar.css";
import axios from "axios";
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import burgurIcon from "../img/burgurIcon.png";
import searchIcon from "../img/searchIcon.png";

const NavBar = ({ onLogout, howIs, empName, handleActions, handleSideBar, isAction }) => {
	const url = process.env.REACT_APP_API_URL;
	const [name, setName] = useState("");
	const [employees, setEmployees] = useState([]);
	const [filteredEmps, setFilteredEmps] = useState([]);

	useEffect(() => {
		axios
			.get(process.env.REACT_APP_API_URL + "/api/employee", {
				headers: {
					authorization: localStorage.getItem("token") || ""
				}
			})
			.then(res => {
				setEmployees(res.data);
				setFilteredEmps(res.data);
			})
			.catch((err) => {
				return console.log(err);
			})

		return () => { }
	}, [])

	const filterEmps = (e) => {
		setName(e);
		if (name === "") {
			setFilteredEmps(employees)
		} else {
			setFilteredEmps(
				employees.filter((emp) =>
					emp.FirstName.toLowerCase().includes(e.toLowerCase())
				)
			);
		}
	};


	return (
		<div className="navParent">
			<div>
				<img src={burgurIcon} alt="" className="burgurImg" />
			</div>

			<div className="searchParent">
				<input type="text" className="" placeholder="Search..." />
				<div className="searchIcon">
					<img src={searchIcon} alt="" className="searchImg" />
				</div>
			</div>

			<div className="iconParent">
				<div className="circleIcon text-light" style={{ backgroundColor: "#253D90" }}>
					<NotificationsRoundedIcon />
				</div>
				<div className="circleIcon" style={{ backgroundColor: "#FFC20E" }}>
					<SettingsRoundedIcon />
				</div>
				<div className="circleIcon text-light" style={{ background: "#3F861E" }}>
					<EmailRoundedIcon />
				</div>
			</div>
		</div>
	)
};

export default NavBar;
