import React, { useEffect, useState } from "react";
import ImportContactsRoundedIcon from '@mui/icons-material/ImportContactsRounded';
import Loading from "../Loading";
import LeaveHistory from "./LeaveHistory";
import axios from "axios";
import "./LeaveManagement.css";
import "../EmployeeHub/style.css";
import { toast } from "react-toastify";
import LeaveTable from "./LeaveTable";
import { fetchLeaveRequests } from "../EmployeeHub/ClockInsApi";

const LeaveManagement = (props) => {
    const url = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("token");
    const Account = localStorage.getItem("Account");
    const empId = localStorage.getItem("_id");
    const [leaveRequests, setLeaveRequests] = useState([]);
    const [leaveOption, setLeaveOption] = useState("history");

    useEffect(() => {
        const fetchEmpLeaveRequests = async () => {
            try {
                const res = await axios.get(`${url}/api/leave-application/hr`, {
                    headers: {
                        authorization: token || ""
                    }
                })
                setLeaveRequests(res.data)
                console.log(res.data);

            } catch (err) {
                if (err.response && err.response.data && err.response.data.message) {
                    console.log(err.response.data.message);
                    toast.error(err.response.data.details);
                }
            }
        }
    if(Account == 2){
        fetchEmpLeaveRequests();
    }else{
        const leaveData =  fetchLeaveRequests(empId);
        setLeaveRequests(leaveData);
    }
       
        return () => {
            setLeaveRequests([])
        }
    }, [])

    return (
        <>
            {/* <div className="my-2">
                <ImportContactsRoundedIcon fontSize="large" />  <b>Leave Management</b>
            </div>
            <div className="leaveOptionParent">
                <div className={`leaveOption ${leaveOption === "settings" && "active"}`} onClick={() => setLeaveOption("settings")}>Leave Settings</div>
                <div className={`leaveOption ${leaveOption === "recall" && "active"}`} onClick={() => setLeaveOption("recall")}>Leave Recall</div>
                <div className={`leaveOption ${leaveOption === "history" && "active"}`} onClick={() => setLeaveOption("history")}>Leave History</div>
                <div className={`leaveOption ${leaveOption === "officers" && "active"}`} onClick={() => setLeaveOption("officers")}>Relief Officers</div>
            </div> */}

            {leaveOption === "history" ?
                (leaveRequests.length > 0 ?
                    <LeaveTable data={leaveRequests} />
                    : <Loading />)
                : leaveOption === "settings" ? <h1>Settings</h1>
                    : leaveOption === "recall" ? <h1>recall</h1>
                        : leaveOption === "officers" ? <h1>Officers</h1> : ""
            }
        </>

    )
};

export default LeaveManagement;
