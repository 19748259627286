import React, { useEffect, useState } from "react"
import LeaveTable from "../hr/LeaveTable";
import axios from "axios";
import Loading from "../Loading";

const Assets = (props) => {
    const url = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("token");
    const [empData, setEmpData] = useState([]);

    useEffect(async () => {
        const emps = await axios.get(`${url}/api/employee`, {
            headers: {
                authorization: token || ""
            }
        });
        setEmpData(emps.data);
    }, [])
    return (
        <div>
            <div className="payslipTitle">
                Assets
            </div>

                {
                    empData.length > 0 
                    ? <LeaveTable data={empData} />
                    : <Loading />
                }
        </div>
    )
};

export default Assets;
