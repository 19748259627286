import React from "react"

const Folder = (props) => {
  return (
    <div>
      <div className="payslipTitle">
        Documents
      </div>
    </div>
  )
};

export default Folder;
